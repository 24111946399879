export const useRedirectToSignup = () => {
  const { routing } = useRoutingStore()

  const redirectToSignup = ({ next }: { next: string }) => {
    if (!isDefined(routing)) {
      throw new Error('routing not defined')
    }
    const url = new URL(routing.sign_up)
    url.searchParams.append('next', next)

    return navigateTo(url.href, { external: true })
  }

  return { redirectToSignup }
}
